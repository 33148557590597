<template>
  <div>
    <div v-if="!showExtra" class="relative">
      <img src="../../assets/slides/Slide8.jpeg" />
      <button
        style="position: absolute;
        bottom: 195px;
        right: 60px;"
        @click="showExtra = true"
        class="button small rounded lower">
        Click here for more detail on what<br />
        to consider when apologising
      </button>
    </div>
    <div v-else class="relative">
      <img src="../../assets/slides/Slide9.jpeg" />
      <button
        style="position: absolute;
        bottom: 80px;
        right: 85px;"
        @click="showExtra = false"
        class="button small rounded lower">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ModuleOnePageFour',
    data() {
      return {
        showExtra: false,
      };
    },
};
</script>
