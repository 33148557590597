<template>
  <div>
    <div v-if="!showExtra" class="relative">
      <img src="../../assets/slides/Slide3.jpeg" />
      <button
        style="position: absolute;
        bottom: 55px;
        right: 60px;"
        @click="showExtra = true"
        class="button small rounded lower">
        Click here for a further explanation of<br />
        the professional duty of candour
      </button>
    </div>
    <div v-else class="relative">
      <img src="../../assets/slides/Slide4.jpeg" />
      <button
        style="position: absolute;
        bottom: 80px;
        right: 85px;"
        @click="showExtra = false"
        class="button small rounded lower">
        Close
      </button>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ModuleOnePageOne',
    data() {
      return {
        showExtra: false,
      };
    },
};
</script>
