<template>
  <div>
    <img src="../../assets/slides/Slide12.jpeg" />
  </div>
</template>

<script>
export default {
  name: 'ModuleOnePageSix',
};
</script>
