<template>
  <div>
    <img src="../../assets/slides/Slide11.jpeg" />
  </div>
</template>

<script>
export default {
  name: 'ModuleOnePageFive',
};
</script>
