<template>
  <div>
    <img src="../../assets/slides/Slide6.jpeg" />
  </div>
</template>

<script>
export default {
  name: 'ModuleOnePageTwo',
};
</script>
