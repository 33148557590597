<template>
  <div>
    <img src="../../assets/slides/Slide7.jpeg" />
  </div>
</template>

<script>
export default {
  name: 'ModuleOnePageThree',
};
</script>
