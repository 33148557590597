<template>
  <div class="content pad-bottom--xl">
    <div class="grid-container pad-top">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad-top">
          <h1 class="text-white large-heading pad-bottom">When things go wrong: A Clinician's duty towards the patient and those close to them</h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 pad bg--white">
          <ul class="tabs">
            <li @click="goToIntro" :class="{ active: page === 0}">Intro</li>
            <li @click="page = 1" :class="{ active: page === 1}">Page 1</li>
            <li @click="page = 2" :class="{ active: page === 2}">Page 2</li>
            <li @click="page = 3" :class="{ active: page === 3}">Page 3</li>
            <li @click="page = 4" :class="{ active: page === 4}">Page 4</li>
            <li @click="page = 5" :class="{ active: page === 5}">Page 5</li>
            <li @click="page = 6" :class="{ active: page === 6}">Page 6</li>
            <li @click="page = 7" :class="{ active: page === 7}">Summary</li>
          </ul>
          <page-1 v-if="page === 1" />
          <page-2 v-if="page === 2" />
          <page-3 v-if="page === 3" />
          <page-4 v-if="page === 4" />
          <page-5 v-if="page === 5" />
          <page-6 v-if="page === 6" />
          <page-7 v-if="page === 7" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Page1 from '@/components/ModuleOne/Page1.vue';
import Page2 from '@/components/ModuleOne/Page2.vue';
import Page3 from '@/components/ModuleOne/Page3.vue';
import Page4 from '@/components/ModuleOne/Page4.vue';
import Page5 from '@/components/ModuleOne/Page5.vue';
import Page6 from '@/components/ModuleOne/Page6.vue';
import Page7 from '@/components/ModuleOne/Page7.vue';

export default {
  name: 'ModuleOne',
  components: {
    Page1,
    Page2,
    Page3,
    Page4,
    Page5,
    Page6,
    Page7,
  },
  data() {
    return {
      page: 1,
    };
  },
  methods: {
    goToIntro() {
      this.$router.push('/module-one');
    },
  },
};
</script>
