<template>
  <div>
    <div>
      <img src="../../assets/slides/Slide13.jpeg" />
    </div>
    <div class="text-center">
      <router-link to="/module-one-quiz">
        <button
          class="button rounded lower">
          Go to module quiz
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModuleOneQuiz',
};
</script>
